



















import { Component, Prop, Vue } from 'vue-property-decorator';

import { BASE_BLACK_COLOR } from '@/utils/constants';

@Component({})
export default class CreateNewReport extends Vue {
  @Prop() action!: Function;

  color: String = BASE_BLACK_COLOR;
}
